import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../../src/styles/figure.css";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "business-central"
    }}>{`Business Central`}</h1>
    <p>{`The ERP system we're working with is called `}<a parentName="p" {...{
        "href": "https://dynamics.microsoft.com/nb-no/business-central/overview/"
      }}>{`Microsoft Dynamics 365 Business Central`}</a>{` (that's a mouthfull), but we call it BC for short.`}</p>
    <p>{`It looks like this:`}</p>
    <figure>
  <img src="/public/images/business-central.png" title="The home screen of Business Central" />
  <figcaption>
    This is the home screen, what you see here is dependent on your role &mdash;
    someone in the accouting department would see different statistics that are
    more relevant to them, the same goes for the people down on the production
    floor.
  </figcaption>
    </figure>
    {
      /* This is the home screen, what you see here is dependent on your role &mdash; someone in the accouting
      department would see different statistics that are more relevant to them, the same goes for the people
      down on the production floor. */
    }
    <p>{`Most of the pages you'll see look like these two:`}</p>
    <figure>
  <img src="/public/images/business-central-item-list.png" title="An overview of the items in Business Central" />
  <figcaption>
    This is a simple list page that shows you a summary of the items the
    business has registered in the system.
  </figcaption>
    </figure>
    <figure>
  <img src="/public/images/business-central-item-card.png" title="The details of a single item in Business Central" />
  <figcaption>
    This is a simple card page that shows in more detail and allows you to edit
    a single item.
  </figcaption>
    </figure>
    <p>{`As you can probably imagine, this is the pattern that repeats itself throughout the system:`}</p>
    <ul>
      <li parentName="ul">{`List of sales orders ➡️ A card with a single sales order`}</li>
      <li parentName="ul">{`List of customers ➡️ A card with a single customer`}</li>
      <li parentName="ul">{`List of `}<em parentName="li">{`something`}</em>{` ➡️ A card of a single `}<em parentName="li">{`something`}</em></li>
    </ul>
    <Navigation previous="/introduction/what-is-erp" next="/introduction/our-job" mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      